import currency from "currency.js";
import get from "lodash/get";
import uniq from "lodash/uniq";
import cloneDeep from "lodash/cloneDeep";
import { Predicate } from "@syncfusion/ej2-data";
import {
  addSeconds,
  format,
  differenceInSeconds,
  formatDistance,
} from "date-fns";
import { it, de, enUS } from "date-fns/locale";
import { AUTO_INVOICE_SCHEDULE, AUTO_INVOICE_TYPE } from "../config/global";

const slugify = (string) => {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

const getOptionLabel = (value, options, key = "label") => {
  const option = options.find((o) => o.value === value);
  return option ? option[key] : "";
};

const defaultIfNull = (value, fallback = "") => {
  return value !== null ? value : fallback;
};

const getTranslation = (translations, lang, field = "name") => {
  return get(translations || {}, `${lang}.${field}`, "");
};

const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};

const formatDate = (date, f = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", options = {}) => {
  return format(date, f, options);
};

const prepareCoordinates = (coordinates) => {
  return [
    coordinates.left,
    coordinates.top,
    coordinates.left + coordinates.width,
    coordinates.top + coordinates.height,
  ]
    .map((cord) => parseInt(cord, 10))
    .join(",");
};

const decodeCoordinates = (coordinatesString) => {
  if (typeof coordinatesString !== "string") {
    return null;
  }

  const chunks = coordinatesString.split(",");
  if (chunks.length !== 4) {
    return null;
  }
  return {
    left: parseInt(chunks[0], 10),
    top: parseInt(chunks[1], 10),
    width: parseInt(chunks[2], 10) - parseInt(chunks[0], 10),
    height: parseInt(chunks[3], 10) - parseInt(chunks[1], 10),
  };
};

const formatPrice = (value, currencySymbol = "") => {
  return currency(value, {
    symbol: `${currencySymbol}`,
    precision: 2,
    pattern: `# !`,
    negativePattern: `-# !`,
  });
};

const haveManyVariants = (lots) => {
  return Array.isArray(lots) && lots.length > 1;
};

const haveOneVariant = (lots) => {
  return Array.isArray(lots) && lots.length === 1;
};

const isWeightProduct = (product) => {
  return product.pw === true;
};

const getProductMarketPriceRange = (lots) => {
  if (haveManyVariants(lots)) {
    const prices = lots.map((lot) => {
      return get(lot, "ppm.price", 0);
    });
    return uniq([Math.min(...prices), Math.max(...prices)]);
  }
  return null;
};

const getProductCardDataFromMarketProduct = (
  product,
  lang,
  currencySymbol,
  unitOptions
) => {
  const lots = product.lmv;
  const uiCardProduct = {
    id: product.pp.id,
    img: product.pp.image
      ? product.pp.image.image_md
      : "https://picsum.photos/id/901/900/600",
    company: get(product, "pp.producer.role.name", ""),
    title: getTranslation(product.pp.translations, lang.locale),
    currency: currencySymbol,
  };

  if (isWeightProduct(product.pp)) {
    uiCardProduct.byWeight = true;
    uiCardProduct.weightUnit = getOptionLabel(product.pp.pw_unit, unitOptions);
  }

  if (haveManyVariants(lots)) {
    uiCardProduct.subtitle = lang.t("family.market.pbox.variants", [
      lots.length,
    ]);
    uiCardProduct.priceRange = getProductMarketPriceRange(lots);
  } else if (haveOneVariant(lots)) {
    const singleVariant = lots[0];
    uiCardProduct.subtitle = getTranslation(
      lots[0].ppl.translations,
      lang.locale
    );
    uiCardProduct.price = get(lots[0], "ppm.price", 0);
    if (isWeightProduct(product.pp)) {
      const minQta = singleVariant.ppl.pw_min;
      const minQtaUnit = getOptionLabel(product.pp.pw_unit, unitOptions);
      if (minQta && minQtaUnit) {
        uiCardProduct.info = lang.t("family.market.pbox.min", [
          minQta,
          minQtaUnit,
        ]);
      }
    } else if (singleVariant.price_base_unit) {
      const { price, unit } = singleVariant.price_base_unit;
      uiCardProduct.info = formatPrice(
        price,
        `${currencySymbol} / ${getOptionLabel(unit, unitOptions)}`
      ).format();
    }
  }
  return uiCardProduct;
};

const diffInSeconds = (dateLeft, dateRight) => {
  return differenceInSeconds(dateLeft, dateRight);
};

const formatTime = (seconds, f = "mm:ss") => {
  const helperDate = addSeconds(new Date(0), seconds);
  return format(helperDate, f);
};

const duration = (s, locale = "it") => {
  // console.log(locale, locale=="de")
  let lang = enUS;
  if (locale == "it") {
    lang = it;
  } else if (locale == "en") {
    lang = enUS;
  } else if (locale == "de") {
    lang = de;
  }
  // console.log(lang)
  return formatDistance(0, s * 1000, { includeSeconds: true, locale: lang });
};

const getCartItemByPpmId = (cart, ppm_id) => {
  if (cart && cart.items && cart.items.length > 0) {
    const v = cart.items.find((item) => {
      return item.view.mp && item.view.mp.ppm.id === ppm_id;
    });
    return v;
  }
  return undefined;
};

const addOrReplace = (array, item, key = "id") => {
  const clone = cloneDeep(array);
  const i = clone.findIndex((_item) => _item[key] === item[key]);
  if (i > -1) {
    clone[i] = item;
  } else {
    clone.push(item);
  }
  return clone;
};

const galleryImage = (image, refId, type) => {
  return {
    key: image.image.id,
    refId: refId,
    type: type,
    thumb: image.image_thumb,
    cropped: image.image_md,
    ratio: image.image_ratio,
    full: image.image.img,
  };
};

const isObject = (val) => {
  if (typeof val === "object" && val !== null && !Array.isArray(val)) {
    return true;
  }
  return false;
};

const addAndPredicateFilter = (pred, field, operator, value) => {
  if (value === "" || value === null) {
    return pred;
  }
  let v = value;
  if (isObject(value) && value.id) {
    v = value.id;
  }

  if (pred) {
    return pred.and(field, operator, v);
  }

  return new Predicate(field, operator, v);
};

const buildPredicate = (pred, map, filtersValues) => {
  for (const field in map) {
    const value = get(filtersValues, field);
    const filterKey = map[field];
    if (filterKey) {
      pred = addAndPredicateFilter(pred, filterKey, "equal", value);
    }
  }
  return pred;
};

const calc_percentage = (val, tot) => {
  return (val * 100) / tot;
};

const processProgressData = (itemCount, statusMap, progressMap) => {
  for (const status in statusMap) {
    const value = statusMap[status];
    const p = progressMap.find((item) => {
      return item.status.map((i) => i.toString()).includes(status);
    });

    if (p) {
      p.value = (p.value || 0) + value;
    }
  }

  const out = progressMap
    .filter((item) => item.value > 0)
    .map((item) => {
      return {
        ...item,
        percentage: calc_percentage(item.value, itemCount),
      };
    });

  return out;
};

const getInvoicingSettingsDefaults = () => {
  return {
    manage_payments: false,
    manage_income: false,
    auto_invoice: false,
    intervalType: AUTO_INVOICE_TYPE.DATE,
    intervalSchedule: AUTO_INVOICE_SCHEDULE.MONTH_BEGINNING,
    intervalLimitAmount: 0,
    payment_terms: "",
  };
};

const parseInvoicingSettings = (data = null) => {
  const defaults = getInvoicingSettingsDefaults();
  if (data === null) {
    return defaults;
  }

  let invoicing = {
    manage_payments: get(data, "manage_payments", defaults.manage_payments),
    manage_income: get(data, "manage_income", defaults.manage_income),
    payment_terms: get(data, "payment_terms", defaults.payment_terms),
  };
  if (get(data, "auto_invoice", null) === null) {
    invoicing = {
      ...invoicing,
      auto_invoice: false,
      intervalType: AUTO_INVOICE_TYPE.DATE,
      intervalSchedule: AUTO_INVOICE_SCHEDULE.MONTH_BEGINNING,
      intervalLimitAmount: 0,
    };
  } else {
    if (get(data, "auto_invoice.based_date", null) !== null) {
      invoicing = {
        ...invoicing,
        auto_invoice: true,
        intervalType: AUTO_INVOICE_TYPE.DATE,
        intervalSchedule: get(data, "auto_invoice.based_date"),
        intervalLimitAmount: 0,
      };
    } else if (get(data, "auto_invoice.based_amount", null) !== null) {
      invoicing = {
        ...invoicing,
        auto_invoice: true,
        intervalType: AUTO_INVOICE_TYPE.AMOUNT_EXCEEDED,
        intervalSchedule: AUTO_INVOICE_SCHEDULE.MONTH_BEGINNING,
        intervalLimitAmount: get(data, "auto_invoice.based_amount", 0),
      };
    }
  }

  return invoicing;
};

const str2bytes = (str) => {
  var bytes = new Uint8Array(str.length);
  for (var i = 0; i < str.length; i++) {
    bytes[i] = str.charCodeAt(i);
  }
  return bytes;
};

export {
  slugify,
  getOptionLabel,
  defaultIfNull,
  getTranslation,
  isValidDate,
  formatDate,
  prepareCoordinates,
  decodeCoordinates,
  formatPrice,
  getProductCardDataFromMarketProduct,
  diffInSeconds,
  formatTime,
  duration,
  getCartItemByPpmId,
  addOrReplace,
  galleryImage,
  isObject,
  addAndPredicateFilter,
  buildPredicate,
  processProgressData,
  getInvoicingSettingsDefaults,
  parseInvoicingSettings,
  str2bytes,
};
